import React, { useState, useEffect } from "react";
import './Modal.scss';
import ReactModal from "react-modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BtnClose from "../../../img/BtnClose";
import Alerta from "../Alertas/Alertas";
import { ImgNotFound } from "../../../img/ImgNotFound";

const Modal = ({ modalIsOpen, setModalIsOpen, action, itemModal, setUpdateP, categories, subcategories }) => {
    const [obj, setObj] = useState({});
    const [id, setId] = useState(itemModal.id);
    const [name, setName] = useState(itemModal.name);
    const [descrip, setDescrip] = useState(itemModal.descrip);
    const [state, setState] = useState(itemModal.state);
    const [units, setUnits] = useState(itemModal.units);
    const [id_category, setCategory] = useState(itemModal.id_category);
    const [id_subcategory, setSubCategory] = useState(itemModal.id_subcategory);
    const [price, setPrice] = useState(itemModal.price);
    const [badge, setBadge] = useState(itemModal.badge);
    const [isActive, setIsActive] = useState(itemModal.state === "A");
    const [FilterSubCateg, setFilterSubCateg] = useState([]);
    const [image, setImage] = useState(null);
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
    const [alerta, cambiarAlerta] = useState({});
    const [imageData, setImageData] = useState({
        image: ImgNotFound
    });
    const [toogle,setToogle] = useState(false);

    const [imageInputKey, setImageInputKey] = useState(Date.now());

    const cerrarModal = async () => {
        await setModalIsOpen(false);
        setImage(null);
    };

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setIsActive(checked);
            setState(checked ? "A" : "I");
        } else {
            switch (name) {
                case 'name':
                    setName(value);
                    break;
                case 'descrip':
                    setDescrip(value);
                    break;
                case 'units':
                    setUnits(value);
                    break;
                case 'price':
                    setPrice(value);
                    break;
                case 'badge':
                    setBadge(value);
                    break;
                default:
                    console.log("default Switch");
                    break;
            }
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setImage(base64String); // Guarda la imagen en base64 en el estado `image`
            };
            reader.readAsDataURL(file); // Lee el archivo como una URL de datos
        }
    };

    useEffect(() => {
        if (id_category) {
            const filtered = subcategories.filter(i => i.id_category === id_category);
            setFilterSubCateg(filtered);
        }
    }, [id_category, subcategories]);

    const handleSelect = (e) => {
        const { value } = e.target;
        const selectedOption = e.target.options[e.target.selectedIndex];
        const type = selectedOption.dataset.type;
        if (type === "category") {
            setCategory(value);
            const filtered = subcategories.filter(i => i.id_category === value);
            setFilterSubCateg(filtered);
        }

        if (type === "sub-category") {
            setSubCategory(value);
        }
    };

    useEffect(() => {
        //debugger;
        if (action === "create") {
            setId("0");
            setName("");
            setDescrip("");
            setUnits("");
            setPrice("");
            setBadge("");
            setCategory("");
            setSubCategory("");
            setState("A");
            setIsActive(true);
            setImageData("");
            setImage(null);
            setImageInputKey(Date.now());
            setToogle(true);
        } else {
            setId(itemModal.id);
            setName(itemModal.name);
            setDescrip(itemModal.descrip);
            setUnits(itemModal.units);
            setPrice(itemModal.price);
            setBadge(itemModal.badge);
            setCategory(itemModal.id_category);
            setSubCategory(itemModal.id_subcategory);
            setState(itemModal.state);
            setIsActive(itemModal.state === "A");
            setImage(itemModal.imgMain);
            setToogle(false);
        }
    }, [modalIsOpen , action, itemModal]);

    const handleSubmit = async () => {
        cambiarEstadoAlerta(false);
        cambiarAlerta({});
        if (name !== "" && price !== "") {
            try {
                let postData = {
                    id,
                    name,
                    descrip,
                    state,
                    units,
                    id_category,
                    id_subcategory,
                    price,
                    badge,
                    image: image ? (image.includes("data:image/jpeg;base64,") ? image : `data:image/jpeg;base64,${image}`) : imageData.image
                };

                const response = await axios.post('https://www.obleasdulcesbsas.com.ar/api/setProduct.php', postData);
                if (response.data.state === 200) {
                    setUpdateP(prevUpdateP => !prevUpdateP);

                    /*RESET*/
                    setName("");
                    setDescrip("");
                    setUnits("");
                    setPrice("");
                    setBadge("");
                    cambiarEstadoAlerta(true);
                    cambiarAlerta({
                        tipo: 'exito',
                        mensaje: action === "create" ? "Producto creado con éxito" : "Producto Editado con éxito"
                    });
                    setTimeout(() => {
                        setModalIsOpen(false);
                    }, 2000);
                } else {
                    cambiarEstadoAlerta(true);
                    cambiarAlerta({
                        tipo: 'error',
                        mensaje: response.data.msj
                    });
                }
            } catch (error) {
                cambiarEstadoAlerta(true);
                cambiarAlerta({
                    tipo: 'error',
                    mensaje: error.message
                });
            }
        } else {
            cambiarEstadoAlerta(true);
            cambiarAlerta({
                tipo: 'error',
                mensaje: "Completar campos requeridos(*)"
            });
        }
    };
    return (
        <>
            <ReactModal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                <div className="container-modal">
                    <div className="btn-close" onClick={() => cerrarModal()}>
                        <BtnClose />
                    </div>
                    <div className="container-form">
                        <h1>
                            {action === "create" ? <span>Nuevo Producto</span> : <span>Editando Producto (id:{itemModal.id})</span>}
                        </h1>
                        <div className="form-group">
                            <label>Nombre*</label>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                placeholder="Nombre"
                                onChange={(e) => handleInput(e)}
                            />
                        </div>
                        {/*action === "create" &&
                            <>
                                <div className="form-group">
                                    <label>Categoría*</label>
                                    <select onChange={(e) => handleSelect(e)}>
                                        <option>Seleccione categoría</option>
                                        {categories.map((i, index) => (
                                            <option key={index} value={i.id} data-type="category">
                                                {i.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {FilterSubCateg.length !== 0 &&
                                    <div className="form-group">
                                        <label>Subcategoría*</label>
                                        <select onChange={(e) => handleSelect(e)}>
                                            <option>Seleccione</option>
                                            {FilterSubCateg.map((s, index) => (
                                                <option key={index} value={s.id} data-type="sub-category">
                                                    {s.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                }
                            </>
                        */}
                        <div className="form-group">
                            <label>Categoría*</label>
                            <select value={id_category} onChange={(e) => handleSelect(e)}>
                                <option>Seleccione categoría</option>
                                {categories.map((i, index) => (
                                    <option key={index} value={i.id} data-type="category">
                                        {i.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Subcategoría*</label>
                            <select value={id_subcategory} onChange={(e) => handleSelect(e)}>
                                <option>Seleccione</option>
                                {FilterSubCateg.map((s, index) => (
                                    <option key={index} value={s.id} data-type="sub-category">
                                        {s.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {
                            action === "edit" &&
                            <div className="form-group containerImg">
                                <div className="thumbail">
                                    <img src={itemModal.imgMain} />
                                </div>
                                <span onClick={() =>{ setToogle(!toogle)}}>Modificar Imagen</span>
                            </div>                            
                        }
                        {
                            toogle &&
                            <div className="form-group">
                                <label>Cargar Imagen*</label>
                                <input key={imageInputKey} type="file" accept="image/*" onChange={handleFileChange} required />
                            </div>

                        }
                        <div className="form-group">
                            <label>Descripción</label>
                            <textarea
                                name="descrip"
                                value={descrip}
                                placeholder="Descripción"
                                onChange={(e) => handleInput(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Unidades</label>
                            <input
                                type="number"
                                name="units"
                                value={units}
                                placeholder="Unidades"
                                onChange={(e) => handleInput(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Precio*</label>
                            <input
                                type="number"
                                name="price"
                                value={price}
                                placeholder="Ej: 100"
                                onChange={(e) => handleInput(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Badge</label>
                            <input
                                type="text"
                                name="badge"
                                value={badge}
                                placeholder="Ej: Destacado"
                                onChange={(e) => handleInput(e)}
                            />
                        </div>
                        <div className="form-group check">
                            <label>Activo</label>
                            <input
                                type="checkbox"
                                checked={isActive}
                                onChange={(e) => handleInput(e)}
                            />
                        </div>
                        <div>
                            <div className="btn-update" onClick={() => handleSubmit()}>
                                {action == "create"? "Crear Producto" : "Editar Producto"}
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>
            {estadoAlerta && <Alerta tipo={alerta.tipo} mensaje={alerta.mensaje} estadoAlerta={estadoAlerta} cambiarEstadoAlerta={cambiarEstadoAlerta} />}
        </>
    );
};

export default Modal;
